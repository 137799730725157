import { AnimatePresence } from 'framer-motion';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Homepage, SingleClient } from '../app/index';
import SingleTeam from '../app/SingleTeam/SingleTeam';
import { RoutePattern } from '../components/Navigation/Menu';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <Route exact path="/" component={Homepage} />
        <Route exact path={RoutePattern.SingleClient} component={SingleClient} />
        <Route exact path={RoutePattern.SingleTeam} component={SingleTeam} />
        <Route path="*">Page not found</Route>
      </Switch>
    </AnimatePresence>
  );
}
