import { CSSProperties } from 'react';

interface ContainerProps {
  children?: any;
  className?: string;
  id?: string;
  style?: CSSProperties;
}

export default function Container({ children, id, className, style }: ContainerProps) {
  return (
    <div
      id={id}
      className={`${className && className} px-10 lg:px-24 xl:px-56 3xl:px-80`}
      style={{ ...style }}
    >
      {children}
    </div>
  );
}
