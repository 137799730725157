import V1 from './V1';
import V2 from './V2';
import V3 from './V3';
import V4 from './V4';

interface HeadingProps {
  level: number;
  children?: string;
  lineWidth?: number;
  className?: string;
  animateLine?: boolean;
}

export default function Heading({
  level,
  animateLine,
  lineWidth,
  className,
  children,
}: HeadingProps) {
  switch (level) {
    case 1:
      return <V1 lineWidth={lineWidth} />;
    case 2:
      return <V2 animateLine={animateLine}>{children}</V2>;
    case 3:
      return <V3 className={className}>{children}</V3>;
    case 4:
      return <V4 animateLine={animateLine}>{children}</V4>;
    default:
      return null;
  }
}
