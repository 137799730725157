import KennyMobile from './images/kenny-smith.png';
import VictorMobile from './images/victor-oladipo.png';
import Kenny from './images/kenny-smith-desktop.png';
import Victor from './images/victor-oladipo-desktop.png';

export interface ClientDataTypes {
  id: string;
  mobile_img: string;
  desktop_img: string;
  name: string;
  slug: string;
  short: string;
  detail: string;
  last: string;
}

export const DummyData: ClientDataTypes[] = [
  {
    id: '1',
    mobile_img: KennyMobile,
    desktop_img: Kenny,
    name: 'kenny smith',
    slug: 'kenny-smith',
    short:
      'Kenny Smith is a sports commentator and former professional basketball player in the NBA. After playing college basketball with the North Carolina Tar Heels, earning consensus first-team All-American honors as a senior in 1987, he was selected by Sacramento in the first round of the 1987 NBA draft with the sixth overall pick.',
    detail:
      'Smith was named to the NBA All-Rookie First Team with the Kings. He played in the NBA from 1987 to 1997 as a member of the Sacramento Kings, Atlanta Hawks, MIAMI HEAT, Detroit Pistons, Orlando Magic, and Denver Nuggets. He won two NBA championships with Houston.',
    last:
      "After retiring from playing, Smith became a basketball commentator for the Emmy Award-winning Inside the NBA on TNT. He also works as an analyst for CBS/Turner during the NCAA Men's Division I Basketball Tournament.",
  },
  {
    id: '2',
    mobile_img: VictorMobile,
    desktop_img: Victor,
    name: 'victor olapido',
    slug: 'victor-olapido',
    short:
      'Phenomenal athlete, musician, entrepreneur, investor, and philanthropist are just some of the attributes used in describing Victor Oladipo, a two time NBA All-Star, and one of the most multifaceted superstars the NBA has ever seen. Born in Silver Spring, Maryland to Christopher Oladipo, a native of Siera Leone and a public health executive in Maryland, and Joan Oladipo a Nigerian immigrant nurse, Kehinde Babatunde Victor Oladipo was raised in Upper Marlboro, Maryland with his twin sister Victoria, and his elder sisters Kristine and Kendra.',
    detail: `
      A decorated high school player, Victor played for perennial national powerhouse DeMatha Catholic High School of Hyattsville, MD on a team that featured four future NBA players. His senior year he lead DeMatha to the Washington Catholic Athletic Conference and City championships, where he then received the 2010 Washington Post All-Met First Team and First Team All-WCAC honors. 
      
      Ranked #59 shooting guard by ESPN, Oladipo chose Indiana University over Norte Dame and Xavier, where he rose to national prominence after leading Indiana to the 2011 NCAA Tournament Sweet Sixteen in just his sophomore season. The following year in his junior season, Oladipo became a ‘bonafide collegiate star’ according to ESPN analyst Eamonn Brennan, as he lead Indiana to the 2011/2012 Big Ten championship and First Team All-American honors by Sporting News and United States Basketball Writers Association (USBWA). That year he also was recognized as the Sporting News Men’s College Basketball Player of the Year and the National Co-Defensive Player of the Year as he decided to forgo his senior season at Indiana and entered the 2013 NBA Draft. 
      
      Projected as a lottery pick by ESPN and CBS, and as one of the best two-way guards in draft history, he was selected as the #2 pick by the Orlando Magic. He quickly established himself as one of premier guards in the NBA registering his first triple-double of his career on December 3, 2013, against the Philadelphia 76ers with 26 points, 10 rebounds and 10 assists. In that same game, Micheal Carter-Williams a rookie with the Philadelphia 76ers, registered his first career triple-double. Both players set a NBA record as the first rookies to registered their first tripledoubles in the same game, the first and only time the feat has been recorded in the NBA. That season he also participated in the 2015 NBA All-Star weekend, where he competed in the Rising Stars Challenge and the Slam Dunk contest. He finished the year as an NBA All-Rookie Team selection. 

      He was traded to Oklahoma City Thunder on January 2016 where he went on to partner with the future NBA MVP Russell Westbrook after the departure of NBA All Star and MVP Kevin Durant. He later that season went on to sign a 4 year $84 million contract extension with the Thunder. In July 2017 he was traded to the Indiana Pacers in a blockbuster deal that sent NBA All Star Paul George to the Thunder in exchange for Oladipo and Domantas Sabonis, both who went on to became All-Star players for the Pacers. 
      
      At the Pacers, Victor was began to excel both on and off the court, earning his first NBA AllStar selection and leading the Pacers to the Eastern Conference playoffs, and an epic Game 7 duel in the first round between himself and LeBron James. That break out season saw him also lead the NBA in steals as he earned First Team All-NBA Defensive Team and Third Team All-NBA selections. He was voted the NBA’s Most Improved Player award from the 2017/2018 season and also found time to record and release his first major music project from his newly formed Feathery Music Group (which he co-owns with manager/business partner James “Jay Belly” Henderson), V.O. which spawned the platinum single “Connected” featuring PnB Rock. The official music video has amassed over 1 million views on YouTube. 

      Oladipo continued his high level of play in the 2018/2019 season before a devastating quad injury in a home game January 23, 2019 against the Toronto Raptors. Despite the season ending injury, his play still earned him his second NBA All-Star selection. While recovering from the injury, Oladipo continued his musical pursuits by appearing on the hit FOX show “The Masked Singer” where he impressed the judges with his singing voice and stage presence, as he competed as the masked character Thingamajig. He returned to the court almost one year after his injury January 29, 2020 against the Chicago Bulls and hit a 30 ft bomb with 9 seconds left to send the game into overtime. He only was able to play 13 games before the season was suspended due to the Covid-19 pandemic on March 12, 2020, but was returning to All-Star form averaging over 20 points per game over the last 5 games despite playing with a minute restriction set by the Pacer organization. 

      A savvy business man off the court, Oladipo has endorsement deals with Jordan Brand, Express, Finish Line, and Motorola. He was featured in Wall Street Journal article February 13, 2020 detailing athletes who have seen success in the Private Equity investment arena. Oladipo, under the guidance of manager and business partner James “Jay Belly” Henderson, has amassed an extensive and impressive portfolio of investments including significant stakes in Beyond Meat, Airbnb, Cholula hot sauce, One Up e-Sports app, Adventure Chrysler Dodge and Jeep car dealership, Overtime Sports, Turo, and Genies Technology. 
      
      Combining his business acumen with philanthropic efforts, Oladipo continues to give back to his community and fans. His Dunk Cancer Foundation teamed up with ESPN to donate $25,000 for cancer research, while his other foundation gives shoes to children in impoverished urban areas who cannot afford them. 

      Oladipo has expanded his charitable efforts during the Covid-19 pandemic by recording public service announcements for the NBA, Indiana Pacers, CDC Foundation, and the state of Indiana. He partnered with national food grocery Kroger and Power 96.3 in Indianapolis to give out $10,000 worth of gift cards that help families affected by the pandemic so they could get the essential items they needed. In Orlando, he partnered with Chick Fil-A to provide over 500 lunches to front line doctors, nurses, and support staff for AdVent Health Hospital.`,
    last:
      'With the suspended season, and the success of his appearance on “The Masked Singer” the NBA ask Victor to perform as part of their #NBATogetherLIVE series, where they ask players to showcase their off court talents. The “Song4U” IG Live concert featured Victor performing live versions of a Donny Hathaway classic “Song For You” as well as his hit “Connected” and featured additional performances from platinum singer/songwriter Eric Bellinger, rapper Lil Mosey performing his Billboard charting single “Blueberry Faygo”, and Grammy nominated artist Sway Lee. With the success of the concert, the NBA asked Victor to host Base:Line LIVE, a independent music artist contest sponsored in conjunction with Apple Music and United Masters. The show has had six episodes and included guest judges and appearances from Grammy winning singer/songwriters Ne-Yo and Anthony Hamilton, NBA All-Star Trae Young, TPain, and Retired NBA Champion and on air analyst Kenny Smith.',
  },
];
