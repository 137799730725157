import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import useScrolly from '../Hooks/useScrolly';
import Line from '../Line/Line';

interface V2Props {
  children?: any;
  animateLine?: boolean;
}

export default function V2({ children, animateLine }: V2Props) {
  const line = useRef(null);
  const { gsapSlideToRight } = useScrolly();

  useEffect(() => {
    if (animateLine) {
      gsapSlideToRight(line);
    }
  }, [animateLine, gsapSlideToRight]);

  return (
    <motion.div>
      <div ref={line}>
        <Line lineWidth={200} />
      </div>
      <h2 className="mt-8 text-5xl text-white font-diamante uppercase">{children}</h2>
    </motion.div>
  );
}
