import { TimelineLite } from 'gsap';
import { useEffect, useRef } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import Scrollspy from 'react-scrollspy';
import { baseDelay } from './Background';

export default function Dots() {
  const dots: any = useRef(null);

  useEffect(() => {
    const tl = new TimelineLite();

    tl.from(dots.current, {
      rotate: '45deg',
      x: 80,
      opacity: 0.5,
      delay: baseDelay + 1.8,
    });
  }, []);

  return (
    <div ref={dots} className="flex flex-col">
      <Scrollspy
        items={['home', 'services', 'clients', 'about', 'news', 'contact']}
        currentClassName="dot-is-active"
      >
        <li className="dot block w-3 h-3 mb-5 bg-primary last:mb-0 cursor-pointer">
          <a className="opacity-0" onClick={() => scroll.scrollToTop()}>
            home
          </a>
        </li>
        <li className="dot block w-3 h-3 mb-5 bg-primary last:mb-0 cursor-pointer">
          <a href="#services" />
          <Link className="opacity-0" to="services" smooth={true}>
            services
          </Link>
        </li>
        <li className="dot block w-3 h-3 mb-5 bg-primary last:mb-0 cursor-pointer">
          <Link className="opacity-0" to="clients" smooth={true}>
            clients
          </Link>
        </li>
        <li className="dot block w-3 h-3 mb-5 bg-primary last:mb-0 cursor-pointer">
          <Link className="opacity-0" to="about" smooth={true}>
            about
          </Link>
        </li>
        <li className="dot block w-3 h-3 mb-5 bg-primary last:mb-0 cursor-pointer">
          <Link className="opacity-0" to="news" smooth={true}>
            news
          </Link>
        </li>
        <li className="dot block w-3 h-3 mb-5 bg-primary last:mb-0 cursor-pointer">
          <Link className="opacity-0" to="team" smooth={true}>
            team
          </Link>
        </li>
        <li className="dot block w-3 h-3 mb-5 bg-primary last:mb-0 cursor-pointer">
          <a className="opacity-0" onClick={() => scroll.scrollToBottom()}>
            contact
          </a>
        </li>
      </Scrollspy>
    </div>
  );
}
