import SlickSlider from './SlickSlider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface SliderProps {
  v?: number;
  children: any;
  className?: string;
  infinite?: boolean;
  autoplay?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
}

export default function Slider(props: SliderProps) {
  switch (props.v) {
    case 1:
      return <SlickSlider {...props} />;
    default:
      return null;
  }
}
