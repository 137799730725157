import Home from './Home';
import Services from './Services/Services';
import Clients from './Clients/Clients';
import About from './About/About';
import Contact from './Contact/Contact';
import { Navigation } from '../../components';
import { motion, MotionProps } from 'framer-motion';
import { DummyData as ClientData } from './Clients/DummyData';
import { DummyData as TeamData } from './Team/DummyData';
import Team from './Team/Team';
import News from './News/News';

const fade: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export default function Homepage() {
  return (
    <>
      <motion.div {...fade}>
        <Navigation />
        <Home />
        <Services />
        <Clients
          data={ClientData}
          slidesToScroll={2}
          slidesToShow={2}
          title="clients"
          info="OUR CLIENTS INCLUDE NBA SUPERSTAR AND CURRENT MEMBER OF THE MIAMI HEAT, VICTOR OLADIPO AS WELL AS FORMER NBA CHAMPION AND CURRENT ON-AIR NBA ANALYST FOR TNT, KENNY SMITH."
        />
        <About />
        <News />
        <Team
          data={TeamData}
          slidesToScroll={2}
          slidesToShow={2}
          title="team"
          info="OUR TEAM IS MULTI-FACETED WITH YEARS OF EXPERIENCE IN THE SPORTS, ENTERTAINMENT AND FINANCIAL INDUSTRIES. WITH OUR VAST INFRASTRUCTURE OF CONNECTIONS ACROSS INDUSTRIES, OUR TEAM CONNECTS THE TALENT THAT BRANDS SEEK TO OUR CLIENTS."
        />
        <Contact />
      </motion.div>
    </>
  );
}
