import { motion } from 'framer-motion';

interface LineProps {
  lineWidth?: number;
  className?: string;
  hover?: boolean;
}

export default function Line({ lineWidth, className, hover }: LineProps) {
  const lineExtendVariant = {
    out: { width: 100, backgroundColor: '#f71611' },
    hover: {
      width: lineWidth ? lineWidth : Math.floor((Math.random() - 80) * 120),
      backgroundColor: '#ffffff',
    },
    exit: { width: 0 },
  };

  return (
    <motion.div
      animate={`${hover ? 'hover' : 'out'}`}
      className={`bg-primary rounded-sm ${className}`}
      style={{ height: '3px' }}
      variants={lineExtendVariant}
    ></motion.div>
  );
}
