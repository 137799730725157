import { RoutePattern } from '../../components/Navigation/Menu';

export interface DummyDataTypes {
  title: string;
  description: string;
  href?: string;
}

export const DummyData: DummyDataTypes[] = [
  {
    title: 'services',
    description:
      'ALONG WITH ATHLETE AND ARTIST MANAGEMENT, WE HAVE RECENTLY EXPANDED INTO TELEVISION & FILM PRODUCTION, AS WELL AS FRANCHISE OWNERSHIP!',
    href: RoutePattern.Services,
  },
  {
    title: 'clients',
    description:
      'OUR CLIENTS INCLUDE NBA SUPERSTAR AND CURRENT MEMBER OF THE MIAMI HEAT, VICTOR OLADIPO AS WELL AS FORMER NBA CHAMPION AND CURRENT ON-AIR NBA ANALYST FOR TNT, KENNY SMITH.',
    href: RoutePattern.Clients,
  },
  {
    title: 'about',
    description:
      'WE ARE A FULL-SERVICE MANAGEMENT AGENCY FOUNDED BY JAY “BELLY” HENDERSON IN 2015. OUR MISSION IS TO CREATE AND SUPPORT THE PATHWAY TO STRONG AND LONG LASTING SPORTS & ENTERTAINMENT CAREERS IN EVERY ASPECT.',
    href: RoutePattern.About,
  },
];
