import Slider, { Settings } from 'react-slick';

interface SlickSliderProps {
  children?: any;
  className?: string;
  infinite?: boolean;
  autoplay?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
}

export default function SlickSlider({
  children,
  infinite,
  autoplay,
  slidesToScroll,
  slidesToShow,
  className,
}: SlickSliderProps) {
  const settings: Settings = {
    dots: true,
    className: className,
    arrows: false,
    infinite: infinite ? infinite : true,
    autoplay: autoplay ? autoplay : false,
    speed: 2500,
    slidesToShow: slidesToShow ? slidesToShow : 3,
    slidesToScroll: slidesToScroll ? slidesToScroll : 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return <Slider {...settings}>{children}</Slider>;
}
