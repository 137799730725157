import { useEffect, useRef, useState } from 'react';
import { MenuToggle } from './MenuToggle';
import { useDimensions } from './useDimensions';
import {
  mobileMenuVariant,
  mobileMenuContainerVariant,
  mobileMenuItemVariant,
} from './FramerMotion';
import { Menu, MenuTypes, RoutePattern } from '../Menu';
import { useCycle, motion } from 'framer-motion';
import Logo from '../../../logo.gif';
import { Link } from 'react-scroll';
import { pushAnchor } from '../../Anchors/Anchors';
import { TimelineLite } from 'gsap';
import { baseDelay } from '../../../app/Home/Background';

export default function MobileMenu() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const logoRef = useRef(null);
  const menuRef = useRef(null);

  const [menu, toggleMenu] = useState<string>('!#');

  useEffect(() => {
    const tl = new TimelineLite();

    tl.from(logoRef.current, {
      y: 30,
      opacity: 0,
      delay: baseDelay + 1.4,
    }).from(menuRef.current, {
      y: 30,
      opacity: 0,
      delay: baseDelay + 1.2,
    });
  }, []);

  function handleMenuItemClick(i: MenuTypes) {
    toggleMenu(i.url);
    toggleOpen();
    pushAnchor(i.url);
  }

  return (
    <motion.nav
      className="mobile-menu flex flex-row justify-between items-center md:hidden z-20"
      initial={false}
      custom={height}
      animate={isOpen ? 'open' : 'closed'}
      ref={containerRef}
    >
      <motion.div
        ref={menuRef}
        className="w-full fixed z-50 bg-dark h-0 right-0"
        variants={mobileMenuContainerVariant}
      >
        <motion.ul
          className="flex flex-col justify-center h-full items-center uppercase"
          variants={mobileMenuVariant}
        >
          {Menu.map((i: MenuTypes, idx: number) => {
            return (
              <motion.li
                className={`${menu === i.url ? 'current-menu' : ''}`}
                variants={mobileMenuItemVariant}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                key={idx}
              >
                <Link
                  className="mobile-menu-item font-diamante  text-white opacity-100 hover:text-primary transform text-xl | sm:text-3xl md:text-4xl"
                  onClick={() => handleMenuItemClick(i)}
                  to={i.name}
                  smooth={true}
                  duration={3000}
                >
                  {i.name}
                </Link>
              </motion.li>
            );
          })}
        </motion.ul>
      </motion.div>

      <a ref={logoRef} href={RoutePattern.Home}>
        <img width="100" src={Logo} alt="belly's world" />
      </a>
      <div className="bg-white shadow-md rounded-full h-14 w-14 flex justify-center items-center z-50">
        <MenuToggle toggle={() => toggleOpen()} />
      </div>
    </motion.nav>
  );
}
