import { useEffect, useRef } from 'react';
import { Container } from '../../../../components';
import useScrolly from '../../../../components/Hooks/useScrolly';
import AboutImage4 from '../images/about-image-4.jpg';
import AboutImage5 from '../images/about-image-5.jpg';
import AboutImage6 from '../images/about-image-6.jpg';

export default function LargeScreen() {
  const images = useRef(null);
  const { gsapSlideDownStagger } = useScrolly();

  useEffect(() => {
    gsapSlideDownStagger(images);
  }, []);

  return (
    <div className="hidden lg:block">
      <div ref={images} className="flex flex-row my-24">
        <div className="w-full h-full mr-10">
          <img className="w-full h-full object-cover" src={AboutImage4} alt="guys with a suit" />
        </div>
        <div className="w-full h-full mr-10">
          <img className="w-full h-full object-cover" src={AboutImage5} alt="guys with joe biden" />
        </div>
        <div className="w-full h-full mr-0">
          <img className="w-full h-full object-cover" src={AboutImage6} alt="smily people" />
        </div>
      </div>
      <Container className="block">
        <p>
          A storied entertainment industry veteran, Jay earned his BS in Management with a minor in
          Public Policy from Penn State before a long illustrious career in the music industry.
        </p>
        <p className="mt-5">
          Holding various positions from major labels including A&R, marketing, and tour management,
          Jay has worked with countless artists including the legendary Jay Z, 50 Cent, Fat Joe, and
          was instrumental in helping his personal friend and later business partner Hall of Fame
          NBA player Shaquille O'Neal become the only NBA player who achieved Platinum album as a
          music artist.
        </p>
        <p className="mt-5">
          He and Oladipo now co-own Feathery Music Group, which released his first music project
          "V.O." containing the platinum single, "Connected".
        </p>
      </Container>
    </div>
  );
}
