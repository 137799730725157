import { motion } from 'framer-motion';
import { TimelineLite } from 'gsap';
import { useEffect, useRef } from 'react';
import { baseDelay } from '../../app/Home/Background';
import Line from '../Line/Line';

interface V1Props {
  lineWidth?: number;
}

export default function V1({ lineWidth }: V1Props) {
  const heading = useRef(null);
  const line = useRef(null);

  useEffect(() => {
    const tl = new TimelineLite();

    tl.from(heading.current, {
      y: 80,
      duration: 5,
      opacity: 0,
      delay: baseDelay + 0.8,
    }).from(
      line.current,
      {
        x: -50,
        opacity: 0,
      },
      '-=.4',
    );
  }, []);

  return (
    <motion.div className="relative">
      <div ref={line}>
        <Line lineWidth={lineWidth} />
      </div>
      <div className="overflow-hidden">
        <h1
          ref={heading}
          className="mt-5 font-diamante uppercase text-5xl md:text-7xl lg:text-8xl leading-none"
        >
          jay <br />
          <span className="mt-3 block"></span>
          henderson
        </h1>
      </div>
    </motion.div>
  );
}
