import { useEffect, useRef } from 'react';
import { Container, Heading } from '../../../components';
import Divider from '../../../components/Divider/Divider';
import useScrolly from '../../../components/Hooks/useScrolly';

export default function Services() {
  const heading = useRef(null);
  const paragraph = useRef(null);
  const { gsapSlideToDown } = useScrolly();

  useEffect(() => {
    gsapSlideToDown(heading);
    gsapSlideToDown(paragraph);
  }, []);

  return (
    <Container id="services" className="bg-black">
      <Divider />
      <div className="pt-32">
        <div ref={heading}>
          <Heading level={2}>services</Heading>
        </div>
        <p ref={paragraph} className="uppercase mt-6">
          ALONG WITH ATHLETE AND ARTIST MANAGEMENT, WE HAVE RECENTLY EXPANDED INTO TELEVISION & FILM
          PRODUCTION, AS WELL AS FRANCHISE OWNERSHIP!
        </p>
      </div>
      {/* <div className="mt-10 flex flex-col lg:flex-row">
        <Accordion preExpanded={0} data={[...DummyData, ...DummyData2]} />
      </div> */}
    </Container>
  );
}
