import { Container, Heading } from '../../../components';
import Form from './Form';
// import BellysWorld from '../../../assets/images/bellys-world.jpg';
import BellysWorld from '../../../logo.gif';

export default function Contact() {
  return (
    <Container
      id="contact"
      className="py-10 pt-32 lg:flex lg:justify-center lg:items-center lg:pt-10"
      style={{ backgroundColor: '#030303' }}
    >
      <div className="w-full flex flex-col lg:flex-row items-center">
        <div className="w-full">
          <Heading level={2}>contact me</Heading>
          <Form />
        </div>
        <div className="w-full h-full">
          <img className="w-full h-full" src={BellysWorld} alt="bellys world" />
        </div>
      </div>
    </Container>
  );
}
