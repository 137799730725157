import { Card, Slider } from '../../components';
import { DummyData } from './DummyData';

export default function Slides() {
  return (
    <div className="block lg:hidden">
      <Slider className="home-highlights-slider" infinite={false} v={1}>
        {DummyData.map((item, idx) => (
          <div key={idx}>
            <Card
              v={1}
              className="bg-dark px-7 py-8 flex flex-col last:mr-0 mt-10 mb-5 mr-0 md:mr-10 md:mb-0"
              key={idx}
              title={item.title}
              description={item.description}
              href={item.href}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
