import { motion } from 'framer-motion';
import * as React from 'react';

const Path = (props: any) => (
  <motion.path fill="#f71611" strokeWidth="3" strokeLinecap="round" {...props} />
);

interface MenuToggleProps {
  toggle: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const MenuToggle = ({ toggle }: MenuToggleProps) => (
  <button className="outline-none focus:outline-none" onClick={toggle}>
    <svg style={{ fill: '#ffffff' }} width="23" height="23" viewBox="0 0 23 23" stroke="red">
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </button>
);
