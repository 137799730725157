import { Menu } from '../Menu';
import Logo from '../../../logo.gif';
import Anchor from './Anchor';
import Track from '../../Track/Track';
import { useEffect, useRef } from 'react';
import { TimelineLite } from 'gsap';
import { baseDelay } from '../../../app/Home/Background';

interface LargeScreenProps {
  without?: boolean;
  abel?: boolean;
  noActive?: boolean;
}

export function LargeScreen({ without, abel, noActive }: LargeScreenProps) {
  const logo = useRef(null);

  useEffect(() => {
    const tl = new TimelineLite();

    tl.from(logo.current, {
      y: 30,
      opacity: 0,
      stagger: 0.2,
      delay: baseDelay + 1.4,
    });
  }, []);

  return (
    <div className="hidden md:flex flex-row justify-between items-center bg-transparent">
      {!without && (
        <a ref={logo} href="/">
          <img className="w-100 h-full" width="100" src={Logo} alt="bellys world" />
        </a>
      )}
      <Track className={`flex justify-between uppercase  ${abel ? 'font-abel' : 'font-diamante'}`}>
        {Menu.map((item, idx) => {
          return <Anchor noActive={noActive} key={idx} item={item} />;
        })}
      </Track>
    </div>
  );
}
