import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { md } from '../../../helpers/Screensize';

export default function BatchReveal(ref?: any) {
  const config = { threshold: 0.5 };

  let observer = new IntersectionObserver(function (entries, self) {
    let targets = entries.map((entry) => {
      if (entry.isIntersecting) {
        self.unobserve(entry.target);
        return entry.target;
      }
      return false;
    });
    Trigger();
  }, config);

  document.querySelectorAll('.model-item').forEach((box) => {
    observer.observe(box);
  });

  let models = document.querySelectorAll('.model-item');

  function Trigger() {
    ScrollTrigger.batch(models, {
      interval: 0.5,
      batchMax: md() ? 1 : 3,
      onEnter: (batch) => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.35, overwrite: true }),
      onLeave: (batch) => gsap.set(batch, { autoAlpha: 0, y: -50, overwrite: true }),
      onEnterBack: (batch) =>
        gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.35, overwrite: true }),
      onLeaveBack: (batch) => gsap.set(batch, { autoAlpha: 0, y: -50, overwrite: true }),
    });
  }
}
