import { useEffect, useRef } from 'react';
import useScrolly from '../../../../components/Hooks/useScrolly';
import AboutImage2 from '../images/about-image-2.jpg';
import AboutImage3 from '../images/about-image-3.jpg';

export default function DesktopContent() {
  const image1 = useRef(null);
  const paragraph1 = useRef(null);
  const paragraph1a = useRef(null);
  const paragraph1b = useRef(null);

  const image2 = useRef(null);
  const paragraph2 = useRef(null);
  const paragraph2a = useRef(null);
  const paragraph2b = useRef(null);
  const { gsapSlideToDown } = useScrolly();

  useEffect(() => {
    gsapSlideToDown(paragraph1, '85%', 1);
    gsapSlideToDown(paragraph2, '85%', 1);
    gsapSlideToDown(image1, '85%', 1);
    gsapSlideToDown(image2, '85%', 1);
  }, []);

  return (
    <div className="hidden lg:block">
      <div className="flex flex-col-reverse items-center lg:flex-row my-24">
        <div ref={image1} className="w-full mt-10 lg:mt-0 lg:mr-20">
          <img className="w-full h-full" loading="lazy" src={AboutImage2} alt="two guys laughing" />
        </div>
        <div ref={paragraph1} className="w-full h-full">
          <p ref={paragraph1a}>
            &nbsp; &nbsp; &nbsp;To date, we have secured numerous multi-tiered marketing deals for
            players such as current NBA Slam Dunk Champion Derrick Jones Jr. of the Miami Heat, as
            well as on-air talent Kenny Smith, a former NBA champion with the Houston Rockets and
            current analyst for TNT.
          </p>
          <p ref={paragraph1b} className="mt-5">
            Mr. Henderson, along with his business partner Oladipo, has also amassed an extensive
            portfolio of investments including significant stakes in Beyond Meat, Airbnb, Cholula
            hot sauce, Adventure Chrysler Dodge and Jeep car dealership, Overtime Sports, One Up
            e-Sports app, Turo, and Genies Technology.
          </p>
        </div>
      </div>
      <div className="flex-col hidden lg:flex lg:flex-row items-center">
        <div ref={paragraph2} className="w-full mr-0 lg:mr-24">
          <p ref={paragraph2a}>
            Always focused on giving back to his community, Mr. Henderson has used his extensive
            political ties, which include strong relationships to former US President Barack Obama
            and current US President Joe Biden, to do numerous charitable outreach campaigns.
          </p>
          <p ref={paragraph2b} className="mt-5">
            He currently has organized two different campaigns with Chick Fil-A and national grocery
            chain Kroger, helping people affected by the worldwide pandemic as well as providing
            free lunches for front line healthcare workers.
          </p>
        </div>
        <div ref={image2} className="w-full">
          <img
            className="w-full h-full object-cover"
            loading="lazy"
            src={AboutImage3}
            alt="guys talking"
          />
        </div>
      </div>
    </div>
  );
}
