import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Container, Heading } from '../../components';
import useScrolly from '../../components/Hooks/useScrolly';
import img from '../../assets/images/bellys-world.jpg';
import { RouteComponentProps } from 'react-router-dom';
import { DummyData } from '../Home/Team/DummyData';
import { OtherNav } from '../../components/Navigation/OtherNav/OtherNav';
import OtherMobileMenu from '../../components/Navigation/OtherNav/OtherMobileMenu';
import { motion, MotionProps } from 'framer-motion';
import { RoutePattern } from '../../components/Navigation/Menu';

const fade: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const Background = styled.div`
  background-image: url(${(props: any) => (props.bg ? props.bg : img)});
  background-size: 100%;
  min-height: 40vh;
  @media (min-width: 1024px) {
    background-size: 70%;
    min-height: 60vh;
  }
`;

const BackgroundWrapper = (props: any) => {
  return <Background {...props} />;
};

export default function SingleTeam(props: RouteComponentProps) {
  const heading = useRef(null);
  const paragraph = useRef(null);
  const { gsapSlideToDown } = useScrolly();

  const slug = (props.match.params as { slug: string }).slug;

  const currentItem = DummyData.filter((i) => i.slug === slug)[0];

  const image1 = useRef(null);
  const paragraph1 = useRef(null);

  const image2 = useRef(null);
  const paragraph2 = useRef(null);

  useEffect(() => {
    gsapSlideToDown(heading);
    gsapSlideToDown(paragraph);
    gsapSlideToDown(paragraph1, '85%', 1);
    gsapSlideToDown(paragraph2, '85%', 1);
    gsapSlideToDown(image1, '85%', 1);
    gsapSlideToDown(image2, '85%', 1);
  }, [gsapSlideToDown]);

  return (
    <>
      <OtherNav page={RoutePattern.Team} />
      <div className="py-5 px-10 z-50">
        <OtherMobileMenu />
      </div>
      <motion.div {...fade}>
        <Container className="pt-24">
          <div ref={heading}>
            <Heading level={4}>{currentItem.name}</Heading>
          </div>
          <p ref={paragraph} className="text-2xl mt-10 uppercase">
            {currentItem.short}
          </p>
          <BackgroundWrapper
            bg={currentItem.desktop_img}
            className="mt-0 md:mt-16 bg-local md:bg-fixed bg-no-repeat bg-center w-full h-1/2"
          />
          <div className="flex flex-col items-center text-2xl my-0 md:my-24 lg:my-36">
            <div ref={paragraph1} className="w-full h-ful whitespace-pre-wrap">
              {currentItem.detail}
            </div>
          </div>
          <div className="my-10 flex flex-col items-center text-2xl md:my-24 lg:my-36 lg:flex-row">
            <div ref={image1} className="w-full mt-10 lg:mt-0 lg:mr-20">
              <img
                className="w-full h-full"
                loading="lazy"
                src={currentItem.mobile_img}
                alt={currentItem.name}
              />
            </div>
            <div ref={paragraph1} className="mt-10 lg:mt-0 w-full h-ful whitespace-pre-wrap">
              {currentItem.last}
            </div>
          </div>
        </Container>
      </motion.div>
    </>
  );
}
