import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { DummyDataTypes } from '../../app/Home/DummyData';
import truncate from '../../helpers/truncate';
import { pushAnchor } from '../Anchors/Anchors';
import { cardNeonVariant, iconRotate, wipedownVariant } from '../Framer/Framer';
import Heading from '../Heading/Heading';
import Line from '../Line/Line';

const MotionDiv = styled(motion.div)`
  @media (min-width: 1024px) {
    flex: 1;
  }
`;

interface V1Props extends DummyDataTypes {
  className?: string;
}

export default function V1({ title, description, href, className }: V1Props) {
  const [hover, setHover] = useState(false);

  return (
    <MotionDiv
      initial="initial"
      animate="animate"
      whileHover="hover"
      whileTap="tap"
      className={className + ' relative w-full h-full overflow-hidden'}
      variants={cardNeonVariant}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <div className="z-20 flex flex-col">
        <Heading className="mb-5" level={3}>
          {title}
        </Heading>
        <Line hover={hover} lineWidth={90} className="bg-white" />
        <p className="my-5">{truncate(description)}</p>
        <motion.div
          className="ml-auto w-5 h-5"
          variants={iconRotate}
          animate={hover ? 'animate' : 'initial'}
          onMouseOver={() => setHover(true)}
        >
          <Link
            to={title}
            smooth={true}
            duration={1000}
            className="ml-auto mt-auto h-10 w-10 block cursor-pointer"
            onClick={() => pushAnchor(href)}
          >
            <FontAwesomeIcon className="text-2xl" icon={faAngleDoubleRight} />
          </Link>
        </motion.div>
      </div>
      <motion.div
        className="absolute w-full left-0 behind"
        animate={hover ? 'animate' : 'initial'}
        variants={wipedownVariant}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      ></motion.div>
    </MotionDiv>
  );
}
