import { ClientDataTypes } from '../Clients/DummyData';

// team section
import Wayne from './images/wayne.jpeg';
import Jay from './images/jay.jpg';

export interface TeamDataTypes extends ClientDataTypes {}

export const DummyData: TeamDataTypes[] = [
  {
    id: '1',
    mobile_img: Wayne,
    desktop_img: Wayne,
    name: 'Wayne Parris',
    slug: 'wayne-parris',
    short:
      "Wayne Parris previously worked in the retail banking industry for 5+ years after receiving a Bachelor's degree in Economics in 2013. ",
    detail:
      'Mr. Parris has worked closely in conjunction with partners in the finance industry, including financial advisors and merchant representatives, managing finances for clients and businesses on a day to day basis. Met face-to-face with clients to assure the continued financial growth while diversifying their portfolios. ',
    last:
      'More recently, Mr. Parris has operated part time under under the mentorship of Ryan Buhain of 2HandsBasketball to train and coach players at the youth level. With these connections, Mr. Parris presently serves to connect brands with the talent they seek in a variety of ways. Additionally, Mr. Parris is able to provide notary services to clients as he is a registered and active Notary Public in the State of Florida.',
  },
  {
    id: '2',
    mobile_img: Jay,
    desktop_img: Jay,
    name: 'Jay Henderson',
    slug: 'jay-henderson',
    short:
      'A storied entertainment industry veteran, Jay earned his BS in Management with a minor in Public Policy from Penn State. He then began a long illustrious career in the music industry, holding various positions for major labels including A&R, marketing, and tour management.',
    detail: `Jay has worked with countless artists including the legendary Jay Z, 50 Cent, Fat Joe, and was instrumental in helping his personal friend and later business partner Hall of Fame NBA player Shaquille O'Neal become the only NBA player who achieved Platinum album as a music artist. Along with Victor Oladipo, Jay is now co-owner of Feathery Music Group, which released its first music project "V.O." containing the platinum single, "Connected".`,
    last:
      'Jay’s network includes strong relationships to former US President Barack Obama and current US President Joe Biden, with whom he has participated in many charitable outreach campaigns',
  },
];
