import { useEffect, useRef } from 'react';
import { Container, Heading } from '../../../components';
import Divider from '../../../components/Divider/Divider';
import useScrolly from '../../../components/Hooks/useScrolly';

export default function News() {
  const heading = useRef(null);
  const paragraph = useRef(null);
  const { gsapSlideToDown } = useScrolly();

  useEffect(() => {
    gsapSlideToDown(heading);
    gsapSlideToDown(paragraph);
  }, []);

  return (
    <Container id="news" className="bg-black">
      <Divider />
      <div className="pt-32">
        <div ref={heading}>
          <Heading level={2}>News</Heading>
        </div>
        <p ref={paragraph} className="uppercase mt-6">
          Voyager Digital announces partnership with two-time NBA All-Star Victor Oladipo
          <div>
            <a
              href="https://www.newswire.ca/news-releases/voyager-digital-announces-partnership-with-two-time-nba-all-star-victor-oladipo-893105799.html"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold"
            >
              read more
            </a>
          </div>
        </p>
      </div>
      {/* <div className="mt-10 flex flex-col lg:flex-row">
        <Accordion preExpanded={0} data={[...DummyData, ...DummyData2]} />
      </div> */}
    </Container>
  );
}
