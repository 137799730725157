import { Container, Heading } from '../../../components';
import LargeScreen from './Desktop/LargeScreen';
import DesktopContent from './Desktop/DesktopContent';
import MobileContent from './Mobile/MobileContent';
import { StyledBackground } from './StyledComponents';
import { useEffect, useRef } from 'react';
import useScrolly from '../../../components/Hooks/useScrolly';
import { md } from '../../../helpers/Screensize';
import { DummyData } from '../DummyData';

export default function About() {
  const heading = useRef(null);
  const paragraph = useRef(null);
  const background = useRef(null);
  const { gsapSlideToDown, gsapZoom } = useScrolly();

  useEffect(() => {
    gsapSlideToDown(heading);
    gsapSlideToDown(paragraph);
    if (window.innerWidth >= 1024) {
      gsapZoom(background);
    }
  }, [gsapSlideToDown, gsapZoom]);

  return (
    <div id="about" className="pt-32" style={{ backgroundColor: `${md() ? '#000000' : 'none'}` }}>
      <Container>
        <div ref={heading}>
          <Heading level={2}>about</Heading>
        </div>
        <p ref={paragraph} className="mt-10 uppercase">
          {DummyData[2].description}
        </p>
      </Container>
      <StyledBackground
        ref={background}
        className="mt-0 md:mt-16 bg-local md:bg-fixed bg-no-repeat bg-center w-full h-full"
      />
      <Container>
        <DesktopContent />
        <MobileContent />
      </Container>
      <LargeScreen />
    </div>
  );
}
