import Scrollspy from 'react-scrollspy';

interface TrackProps {
  children: any;
  className?: string;
}

export default function Track({ children, className }: TrackProps) {
  return (
    <Scrollspy
      items={['home', 'services', 'about', 'contact', 'clients', 'services']}
      currentClassName="is-current"
      className={className}
    >
      {children}
    </Scrollspy>
  );
}
