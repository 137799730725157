import { motion } from 'framer-motion';
interface V3Props {
  children: any;
  className?: string;
}

export default function V3({ children, className }: V3Props) {
  return (
    <motion.h3 className={`uppercase font-diamante text-2xl ${className}`}>{children}</motion.h3>
  );
}
