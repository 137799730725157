import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import useScrolly from '../Hooks/useScrolly';

interface V4Props {
  children?: any;
  animateLine?: boolean;
}

export default function V4({ children, animateLine }: V4Props) {
  const line = useRef(null);
  const { gsapSlideToRight } = useScrolly();

  useEffect(() => {
    if (animateLine) {
      gsapSlideToRight(line);
    }
  }, [animateLine, gsapSlideToRight]);

  return (
    <motion.div>
      <h2 className="mt-8 text-left lg:text-center text-5xl text-white font-diamante uppercase">
        {children}
      </h2>
    </motion.div>
  );
}
