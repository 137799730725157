import { useEffect, useMemo } from 'react';
import Scrolly from './Scrolly/Scrolly';

export default function useScrolly() {
  useEffect(() => {
    let cleanup: (() => void)[] = [];
    const {
      gsapSlideDownStagger,
      gsapSlideToDown,
      gsapSlideToLeft,
      gsapSlideToRight,
      gsapBatchReveal,
    } = new Scrolly();

    cleanup = [
      gsapSlideDownStagger,
      gsapSlideToDown,
      gsapSlideToLeft,
      gsapSlideToRight,
      gsapBatchReveal,
    ];

    return () => {
      cleanup.forEach((fn) => fn());

      // ScrollTrigger.getAll().forEach((instance) => {
      //   instance.kill();
      // });
      // // This in case a scroll animation is active while the route is updated
      // gsap.killTweensOf(window);
    };
  });

  return useMemo(() => new Scrolly(), []);
}
