import { useState } from 'react';
import Button from '../../../components/Button/Button';
import { gsForm } from './helpers/Form';

export default function Form() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();

      const isSubmitted = await gsForm(name, email);

      if (isSubmitted) {
        setName('');
        setEmail('');
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="w-full lg:w-5/6 mt-10">
      <div className="flex flex-col lg:flex-row items-center">
        <label className="mr-0 lg:mr-5 uppercase" htmlFor="name">
          name
        </label>
        <input
          className="w-full h-full px-2 py-2 text-black"
          id="name"
          name="name"
          type="text"
          onChange={(e) => setName(e.currentTarget.value)}
          value={name}
        />
      </div>
      <div className="flex flex-col lg:flex-row items-center mt-8">
        <label className="mr-0 lg:mr-4 uppercase" htmlFor="name">
          email
        </label>
        <input
          className="w-full h-full px-2 py-2 text-black"
          id="email"
          name="email"
          type="email"
          onChange={(e) => setEmail(e.currentTarget.value)}
          value={email}
        />
      </div>
      <div className="text-center lg:text-right mt-8">
        <Button type="submit">submit</Button>
      </div>
    </form>
  );
}
