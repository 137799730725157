import $ from 'jquery';
import Swal from 'sweetalert2';
import { primary } from '../../../../constant';

export async function gsForm(name: string, email: string) {
  let isSubmitted = false;

  const url =
    'https://script.google.com/macros/s/AKfycby2uSFM7qWmr_4Prfjjn53pW7NVZ3Nj1kZL7gbflEojdp2o2YIHheiUcxmr0xTaXEx1-Q/exec';
  let validData = true;

  if (!validateEmail(email)) {
    validData = false;
    isSubmitted = false;

    Swal.fire({
      title: 'ERROR!',
      text: 'PLEASE ENTER A VALID EMAIL.',
      icon: 'error',
      iconColor: primary,
      allowEscapeKey: false,
      confirmButtonColor: primary,
    });

    return isSubmitted;
  }

  if (validData) {
    isSubmitted = true;
    $.ajax(url, {
      data: {
        Email: email,
        Name: name,
        Date: getCurrentDate(),
        IP: await getUsersIp(),
      },
    });
    Swal.fire({
      title: 'Thank You!',
      icon: 'success',
      iconColor: primary,
      allowEscapeKey: false,
      confirmButtonColor: primary,
    });

    return isSubmitted;
  }

  function validateEmail(val: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(val).toLowerCase());
  }

  async function getUsersIp() {
    let ip;
    await $.get('https://api.ipify.org/?format=json', (data: any) => {
      ip = data.ip;
    });
    return ip;
  }

  function getCurrentDate() {
    const date = new Date().toLocaleDateString('en-US', {
      timeZone: 'America/New_York',
    });
    const time = new Date().toLocaleTimeString('en-US', {
      timeZone: 'America/New_York',
    });
    return `${date} - ${time} (GMT-4)`;
  }
}
