import gsap from 'gsap';
import useSanitizer from '../useSanitizer';

export default function Zoom(ref?: any) {
  const target = useSanitizer(ref);

  gsap.fromTo(
    target,
    {
      y: 0,
      scale: 1,
      opacity: 1,
    },
    {
      y: 30,
      scale: 1.2,
      opacity: 1,
      scrollTrigger: {
        trigger: target,
        start: `top 50%'}`,
        toggleActions: 'play restart resume reset',
        scrub: 1,
        pin: false,
      },
    },
  );
}
