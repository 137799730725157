import { TimelineLite } from 'gsap';
import { useEffect, useRef } from 'react';
import { Card } from '../../components';
import { baseDelay } from './Background';
import { DummyData } from './DummyData';

export default function Highlights() {
  const cards: any = useRef(null);

  useEffect(() => {
    const tl = new TimelineLite();
    tl.from(cards.current.children, {
      y: 80,
      opacity: 0,
      duration: 3,
      stagger: 0.5,
      delay: baseDelay + 1,
    });
  }, []);

  return (
    <div ref={cards} className="hidden lg:gap-10 lg:grid lg:grid-cols-3">
      {DummyData.map((item, idx) => (
        <Card
          key={idx}
          v={1}
          title={item.title}
          description={item.description}
          href={item.href}
          className="px-7 py-8 bg-dark mt-10 mb-5 flex flex-col items-center justify-center | md:mr-10 md:mb-0"
        />
      ))}
    </div>
  );
}
