import { useEffect, useRef } from 'react';
import { Container } from '../../components';
import Button from '../../components/Button/Button';
import Background, { baseDelay } from './Background';
import Dots from './Dots';
import Highlights from './Highlights';
import Slides from './Slides';
import { TimelineLite } from 'gsap';

export default function Home() {
  const stroke = useRef(null);
  const button = useRef(null);

  useEffect(() => {
    const tl = new TimelineLite();
    tl.from(button.current, {
      y: 80,
      opacity: 0,
      delay: baseDelay + 1.6,
    }).from(stroke.current, {
      top: 90,
      duration: 3,
      opacity: 0,
    });
  }, []);

  return (
    <Container id="home" className="relative w-full min-h-screen flex flex-col bg-dark-2">
      <div className="fixed right-5 transform top-1/2 -translate-y-1/2 z-10 hidden md:block">
        <Dots />
      </div>
      <div className="z-10">
        <Background />
      </div>
      <div className="mt-auto z-20">
        <div ref={button}>
          <Button href="mailto:info@bellysworld.com">contact us</Button>
        </div>
      </div>
      <div className="mt-0 lg:mt-auto z-20">
        <Highlights />
        <Slides />
      </div>
    </Container>
  );
}
