import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { neonVariant } from '../Framer/Framer';

interface ButtonProps {
  children: any;
  href?: string;
  type?: 'submit';
  useLink?: string;
}

export default function Button({ children, href, type, useLink }: ButtonProps) {
  return (
    <motion.button
      initial="initial"
      whileHover="hover"
      whileTap="tap"
      variants={neonVariant}
      type={type ? type : 'button'}
      className={`text-white text-xl px-7 py-2 border-primary border-2 uppercase relative outline-red focus:outline-none`}
    >
      {!href && type === 'submit' && children}

      {!!href && (
        <a className="z-10" href={href}>
          {children}
        </a>
      )}

      {!!useLink && (
        <Link className="z-10" to={useLink}>
          {children}
        </Link>
      )}
    </motion.button>
  );
}
