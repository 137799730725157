import { Suspense, useEffect, useRef } from 'react';
import { Preloader, Footer } from './components';
import Parallax from 'parallax-js';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap, { CSSPlugin } from 'gsap';
import './styles/main.css';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import ScrollToTop from 'react-router-scroll-top';

gsap.registerPlugin(CSSPlugin, ScrollTrigger);
gsap.config({
  nullTargetWarn: false,
});

function App() {
  const app = useRef(null);

  useEffect(() => {
    // if (!lg()) {
    //   disableScroll();
    // }

    gsap.to(app.current, {
      css: { visibility: 'visible' },
      duration: 5,
      // onComplete: () => enableScroll(),
    });

    window.onload = () => {
      const scene = document.getElementById('scene');
      if (!scene) return;
      new Parallax(scene);
    };
  }, []);

  return (
    <div ref={app} className="app">
      <Suspense fallback={<Preloader />}>
        <BrowserRouter>
          <ScrollToTop>
            <Switch>
              <Route path="*" component={AppRoutes} />
            </Switch>
            <Footer />
          </ScrollToTop>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
