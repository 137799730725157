import gsap from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SlideDownStagger from './SlideDownStagger';
import SlideDown from './SlideDown';
import SlideLeft from './SlideLeft';
import SlideRight from './SlideRight';
import BatchReveal from './BatchReveal';
import Zoom from './Zoom';

gsap.registerPlugin(CSSPlugin, ScrollTrigger);
ScrollTrigger.config({
  // default is "resize,visibilitychange,DOMContentLoaded,load" so we can remove "resize" from the list:
  autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load',
});

export default class Scrolly {
  gsapSlideToRight(ref?: any, scrollStart?: string) {
    if (!ref) return;
    return SlideRight(ref, scrollStart);
  }

  gsapSlideToLeft(ref?: any, scrollStart?: string) {
    if (!ref) return;
    return SlideLeft(ref, scrollStart);
  }

  gsapSlideToDown(ref?: any, scrollStart?: string, opacity?: number) {
    if (!ref) return;
    return SlideDown(ref, scrollStart, opacity);
  }

  gsapSlideDownStagger(ref?: any, scrollStart?: string, opacity?: number) {
    if (!ref) return;
    return SlideDownStagger(ref, scrollStart, opacity);
  }

  gsapZoom(ref?: any) {
    return Zoom(ref);
  }

  gsapBatchReveal(ref?: any) {
    if (!ref) return;
    return BatchReveal(ref);
  }
}
