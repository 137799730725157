export interface MenuTypes {
  name: string;
  url: string;
  default?: string;
}

export enum RoutePattern {
  Home = '/#home',
  Services = '/#services',
  Projects = '/#projects',
  Clients = '/#clients',
  Team = '/#team',
  About = '/#about',
  News = '/#news',
  Contact = '/#contact',
  SingleClient = '/clients/:slug',
  SingleTeam = '/team/:slug',
}

export const Routes = ['home, services, clients, team, about, contact'];

export const Menu: MenuTypes[] = [
  {
    name: 'home',
    url: RoutePattern.Home,
    default: '/',
  },
  {
    name: 'services',
    url: RoutePattern.Services,
  },
  {
    name: 'clients',
    url: RoutePattern.Clients,
  },
  {
    name: 'about',
    url: RoutePattern.About,
  },
  {
    name: 'news',
    url: RoutePattern.News,
  },
  {
    name: 'team',
    url: RoutePattern.Team,
  },
  {
    name: 'contact',
    url: RoutePattern.Contact,
  },
];

export const Menu2: MenuTypes[] = [
  {
    name: 'home',
    url: '/#home',
  },
  {
    name: 'services',
    url: RoutePattern.Services,
  },
  {
    name: 'clients',
    url: '/#clients',
  },
  {
    name: 'about',
    url: RoutePattern.About,
  },
  {
    name: 'news',
    url: RoutePattern.News,
  },
  {
    name: 'team',
    url: '/#team',
  },
  {
    name: 'contact',
    url: RoutePattern.Contact,
  },
];
