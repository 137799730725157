import { motion } from 'framer-motion';
import { TimelineLite } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { baseDelay } from '../../../app/Home/Background';

interface AnchorProps {
  item: {
    url?: string;
    name?: string;
    default?: string;
  };
  page: string;
}

const extend = {
  initial: { width: '0%', transition: { duration: 10 } },
  hover: { width: '50%', transition: { duration: 0.4 } },
};

export default function OtherAnchor({ item, page }: AnchorProps) {
  const [hover, set] = useState<string | undefined>(page);
  const links = useRef(null);

  useEffect(() => {
    const tl = new TimelineLite();

    tl.from(links.current, {
      y: 30,
      opacity: 0,
      delay: baseDelay + 1.2,
      stagger: 0.2,
    });
  }, []);

  return (
    <motion.li
      ref={links}
      className={`text-lg mr-5 relative cursor-pointer hover:text-primary last:mr-0 ${
        page.includes(item.url ? item.url : 'none') ? 'text-primary' : 'text-white'
      }`}
      onMouseOver={() => set(item.url)}
      onMouseOut={() => set(undefined)}
      animate={
        hover === item.url || page.includes(item.url ? item.url : 'none') ? 'hover' : 'initial'
      }
    >
      <a href={item.url}>{item.name}</a>
      {hover === item.url || page.includes(item.url ? item.url : 'none') ? (
        <motion.span
          className="block absolute -bottom-1 left-0 rounded-md  bg-primary"
          variants={extend}
          whileTap={{ width: 10 }}
          style={{ height: '3px' }}
        />
      ) : null}
    </motion.li>
  );
}
