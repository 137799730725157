import { Variants } from 'framer-motion';

export const primary = '#f71611';
export const dark = '#212121';

export const neonVariant: Variants = {
  initial: {
    color: 'white',
    border: `4px solid ${primary}`,
    backgroundColor: '#000',
  },
  hover: {
    boxShadow: `0 0 10px ${primary}`,
    backgroundColor: primary,
    transition: {
      duration: 0.4,
    },
  },
  tap: {
    boxShadow: `0 0 10px ${primary}`,
    backgroundColor: primary,
    border: `4px solid ${primary}`,
    transition: {
      duration: 0.4,
    },
  },
};

export const wipedownVariant: Variants = {
  initial: {
    background: dark,
    top: 0,
    height: '0%',
  },
  animate: {
    background: primary,
    top: 0,
    height: '100%',
  },
  tap: {
    scale: 1.2,
    background: primary,
  },
};

export const cardNeonVariant: Variants = {
  initial: {
    color: 'white',
  },
  hover: {
    // boxShadow: `0 0 ${!md() ? '20px' : '0px '} ${primary}`,
    transition: {
      duration: 0.4,
    },
  },
};

export const iconRotate: Variants = {
  initial: {
    transform: 'rotate(0deg)',
  },
  animate: {
    transform: 'rotate(90deg)',
  },
  tap: {
    scale: 1.2,
  },
};

export const scaleOnTap: Variants = {
  initial: {
    scale: 0,
    backgroundColor: dark,
  },
  animate: {
    scale: 1.2,
    backgroundColor: primary,
  },
};

export const rotateDownToUp: Variants = {
  initial: {
    transform: 'rotate(0deg)',
  },
  animate: {
    transform: 'rotate(180deg)',
  },
};
