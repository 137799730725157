import { Menu2 } from '../Menu';
import Logo from '../../../logo.png';
import { useEffect, useRef } from 'react';
import { TimelineLite } from 'gsap';
import { baseDelay } from '../../../app/Home/Background';
import OtherAnchor from './OtherAnchor';
import { Container } from '../..';

interface LargeScreenProps {
  without?: boolean;
  abel?: boolean;
  page: string;
}

export function OtherNav({ without, abel, page }: LargeScreenProps) {
  const logo = useRef(null);

  useEffect(() => {
    const tl = new TimelineLite();

    tl.from(logo.current, {
      y: 30,
      opacity: 0,
      stagger: 0.2,
      delay: baseDelay + 1.4,
    });
  }, []);

  return (
    <Container className="hidden md:flex flex-row justify-between items-center">
      {!without && (
        <a ref={logo} href="/">
          <img className="w-100 h-full" width="80" src={Logo} alt="bellys world" />
        </a>
      )}
      <ul className={`flex justify-between uppercase  ${abel ? 'font-abel' : 'font-diamante'}`}>
        {Menu2.map((item, idx) => {
          return <OtherAnchor key={idx} item={item} page={page} />;
        })}
      </ul>
    </Container>
  );
}
