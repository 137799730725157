import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { md } from '../../helpers/Screensize';
import animatedLogoImg from '../../logo.gif';

const Div = styled.div`
  @media (min-width: 1024px) {
    background-image: url(${animatedLogoImg});
    background-size: 55%;
    background-position: right;
    transform: scale(2.5);
    opacity: 0;
  }
`;

export const baseDelay = 0;

export default function Background() {
  const background = useRef(null);
  const mobileBackground = useRef(null);

  useEffect(() => {
    gsap.to(background.current, {
      scale: 1,
      opacity: 1,
      duration: 3.2,
      delay: baseDelay + 0.4,
    });
    if (md()) {
      gsap.from(mobileBackground.current, {
        scale: 1.1,
        opacity: 0,
        duration: 3.2,
        delay: baseDelay + 0.4,
      });
    }
  }, []);

  return (
    <Div
      ref={background}
      className="w-full h-full left-0 absolute top-0 bg-fixed bg-bottom bg-no-repeat behind-2 z-50"
    >
      <img
        ref={mobileBackground}
        className="block lg:hidden w-full h-3/4 object-cover"
        src={animatedLogoImg}
        alt="jay henderson"
      />
    </Div>
  );
}
