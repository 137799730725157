import { DummyDataTypes } from '../../app/Home/DummyData';
import V1 from './V1';

interface CardProps extends DummyDataTypes {
  className?: string;
  v?: number;
}

export default function Card({ title, description, className, href, v }: CardProps) {
  switch (v) {
    case 1:
      return <V1 title={title} description={description} className={className} href={href} />;
    default:
      return null;
  }
}
