import { Slider } from '../../../../components/index';
import AboutImage2 from '../images/about-image-2.jpg';
import AboutImage4 from '../images/about-image-4.jpg';
import AboutImage5 from '../images/about-image-5.jpg';
import AboutImage6 from '../images/about-image-6.jpg';

export default function MobileContent() {
  return (
    <div className="block lg:hidden">
      <Slider v={1} className="about-slider-mobile flex items-end">
        <div className="flex flex-col items-center mt-0 md:mt-24 mb-5">
          <div className="w-full h-full">
            <p>
              &nbsp; &nbsp; &nbsp;To date, we have secured numerous multi-tiered marketing deals for
              players such as current NBA Slam Dunk Champion Derrick Jones Jr. of the Miami Heat, as
              well as on-air talent Kenny Smith, a former NBA champion with the Houston Rockets and
              current analyst for TNT.
            </p>
            <p className="mt-5">
              Mr. Henderson, along with his business partner Oladipo, has also amassed an extensive
              portfolio of investments including significant stakes in Beyond Meat, Airbnb, Cholula
              hot sauce, Adventure Chrysler Dodge and Jeep car dealership, Overtime Sports, One Up
              e-Sports app, Turo, and Genies Technology.
            </p>
          </div>
          <div className="w-full mt-10 ">
            <img className="w-full h-full object-cover" src={AboutImage2} alt="two guys laughing" />
          </div>
        </div>
        <div className="flex-col flex items-center mt-24 mb-5">
          <div className="w-full mr-0 ">
            <p>
              Always focused on giving back to his community, Mr. Henderson has used his extensive
              political ties, which include strong relationships to former US President Barack Obama
              and current US President Joe Biden, to do numerous charitable outreach campaigns.
            </p>
            <p className="mt-5">
              He currently has organized two different campaigns with Chick Fil-A and national
              grocery chain Kroger, helping people affected by the worldwide pandemic as well as
              providing free lunches for front line healthcare workers.
            </p>
          </div>
          <div className="w-full mt-5">
            <img className="w-full h-full object-cover" src={AboutImage4} alt="guys talking" />
          </div>
        </div>
        <div className="flex-col flex items-center mt-24 mb-5">
          <div className="w-full mr-0 ">
            <p className="mt-5">
              Holding various positions from major labels including A&R, marketing, and tour
              management, Jay has worked with countless artists including the legendary Jay Z, 50
              Cent, Fat Joe, and was instrumental in helping his personal friend and later business
              partner Hall of Fame NBA player Shaquille O'Neal become the only NBA player who
              achieved Platinum album as a music artist.
            </p>
          </div>
          <div className="w-full mt-5">
            <img className="w-full h-full object-cover" src={AboutImage5} alt="guys talking" />
          </div>
        </div>
        <div className="flex-col flex items-center mt-24 mb-5">
          <div className="w-full mr-0 ">
            <p className="mt-5">
              He and Oladipo now co-own Feathery Music Group, which released his first music project
              "V.O." containing the platinum single, "Connected".
            </p>
          </div>
          <div className="w-full mt-5">
            <img className="w-full h-full object-cover" src={AboutImage6} alt="guys talking" />
          </div>
        </div>
      </Slider>
    </div>
  );
}
