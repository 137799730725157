import { Container } from '..';
import { LargeScreen } from './Desktop/LargeScreen';
import SmallScreen from './Mobile/SmallScreen';

export default function Navigation() {
  return (
    <Container id="navigation" className="bg-dark-2">
      <LargeScreen />
      <SmallScreen />
    </Container>
  );
}
