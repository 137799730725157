import styled from 'styled-components';

import AboutImage from './images/about-image.jpg';

export const StyledBackground = styled.div`
  background-image: url(${AboutImage});
  background-size: 100%;
  min-height: 60vh;
  @media (min-width: 1024px) {
    background-size: 70%;
    min-height: 100vh;
  }
`;
