import { useEffect, useRef } from 'react';
import { Container, Heading, Slider } from '../../../components';
import Button from '../../../components/Button/Button';
import useScrolly from '../../../components/Hooks/useScrolly';
import './Team.css';
import { TeamDataTypes } from './DummyData';
import styled from 'styled-components';

const TeamItem = styled.div`
  margin: 0 auto;
  display: flex !important;
`;

interface TeamProps {
  data: TeamDataTypes[];
  slidesToShow?: number;
  slidesToScroll?: number;
  title?: string;
  info?: string;
}

export default function Team({ data, slidesToShow, slidesToScroll, title, info }: TeamProps) {
  const heading = useRef(null);
  const paragraph = useRef(null);
  const { gsapSlideToDown } = useScrolly();

  useEffect(() => {
    gsapSlideToDown(heading);
    gsapSlideToDown(paragraph);
  }, [gsapSlideToDown]);

  return (
    <div id="team" className="py-32 bg-black">
      <Container>
        <div ref={heading}>
          <Heading level={2}>{title}</Heading>
        </div>
        <p ref={paragraph} className="uppercase mt-6">
          {info}
        </p>
      </Container>
      <div className="team-container mt-24 px-10 lg:px-0">
        <Slider
          autoplay={true}
          infinite={false}
          v={1}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToScroll}
        >
          {data.map((item, idx) => (
            <TeamItem key={idx} className="team-item relative w-full md:w-3/4 lg:w-1/3 h-full">
              <img
                className="w-full h-full object-cover object-top"
                src={item.mobile_img}
                alt="ig posts"
              />
              <div className="position-center">
                <Button useLink={`/team/${item.slug}`}>view</Button>
              </div>
            </TeamItem>
          ))}
        </Slider>
      </div>
    </div>
  );
}
