import { LargeScreen } from '../Navigation/Desktop/LargeScreen';

export default function Footer() {
  return (
    <div className="flex flex-row justify-between items-center px-10 md:px-24 py-5 bg-black">
      <p className="mx-auto capitalize text-center lg:mx-0">
        {new Date().getFullYear()} &copy; belly's world, all rights reserved.
      </p>

      <div className="hidden lg:block">
        <LargeScreen noActive without abel />
      </div>
    </div>
  );
}
