import { motion } from 'framer-motion';
import { TimelineLite } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-scroll';
import { baseDelay } from '../../../app/Home/Background';
import { pushAnchor } from '../../Anchors/Anchors';
import { RoutePattern } from '../Menu';

interface AnchorProps {
  item: {
    url?: string;
    name?: string;
    default?: string;
  };
  noActive?: boolean;
}

const extend = {
  initial: { width: '0%', transition: { duration: 10 } },
  hover: { width: '50%', transition: { duration: 0.4 } },
};

export default function Anchor({ item, noActive }: AnchorProps) {
  const [hover, set] = useState<string | undefined>(noActive ? undefined : RoutePattern.Home);
  const location = window.location.pathname;
  const links = useRef(null);

  useEffect(() => {
    const tl = new TimelineLite();

    tl.from(links.current, {
      y: 30,
      opacity: 0,
      delay: baseDelay + 1.2,
      stagger: 0.2,
    });
  }, []);

  return (
    <motion.li
      ref={links}
      className={`text-lg mr-5 relative cursor-pointer hover:text-primary last:mr-0 ${
        hover === item.url || location === item.default ? 'text-primary' : 'text-white'
      }`}
      onMouseOver={() => set(item.url)}
      onMouseOut={() => set(undefined)}
      animate={hover === item.url ? 'hover' : 'initial'}
    >
      <Link
        href={item.url}
        to={item.name ? item.name : ''}
        smooth={true}
        duration={1000}
        onClick={() => pushAnchor(item!.url)}
      >
        {item.name}
      </Link>
      {hover === item.url || item.default === location ? (
        <motion.span
          className="block absolute -bottom-1 left-0 rounded-md  bg-primary"
          variants={extend}
          whileTap={{ width: 10 }}
          style={{ height: '3px' }}
        />
      ) : null}
    </motion.li>
  );
}
